.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 12px;
  background: #f3f3f3;
  margin: 6px 0;
  border-radius: 2px;
  width: 100%;
  color: #2E333C;

  gap: 8px;

  h2 {
    color: #000000;
  }

  a {
    color: #243046;
  }

  .btn {
    border: 0;
  }

  .box {
    border: 1px solid #2E333C;
  }

  &.centered {
    align-items: center;
    text-align: center;
  }

  &.raw {
    background: transparent;
    color: inherit;
    padding: 16px 0;

    & > h2 {
      color: #ffffff;
    }

    a {
      color: #ffffff;
    }

    .btn {
      border: 1px solid #00000066;
    }
  }

  &.horizontal {
    flex-direction: row;
  }

  &.full {
    width: 100%;
    & > * {
      flex: 1;
    }
  }


  img {
    max-width: 100%;
  }

}
