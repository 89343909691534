.navbar {
  background: #ffffff;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  .logo {
    width: 160px;
  }
}
