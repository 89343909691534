.box {
  background-color: #f3f3f3;
  padding: 6px;
  position: relative;
  border-radius: 2px;
  min-width: 180px;
  min-height: 90px;
  display: flex;

  .box-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.full {
    width: 100%;
  }

  &.square {
    .box-content {
      position: absolute;
      width: calc(100% - 6px * 2);
      height: calc(100% - 6px * 2);
      display: flex;
      flex-direction: column;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}

.box-group {
  display: flex;
  width: 100%;
  gap: 8px;
  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  h2 {
    color: #000000;
  }

  &.square {
    & > .box {
      .box-content {
        position: absolute;
        width: calc(100% - 6px * 2);
        height: calc(100% - 6px * 2);
        display: flex;
        flex-direction: column;
      }

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  &.primary {
    & > .box {
      background-color: #000000;

      h2 {
        color: #ffffff;
      }
    }
  }

  &.centered {
    > .box > .box-content {
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }

  & > .box {
    flex: 1;
    @media screen and (max-width: 1100px) {
      flex: initial;
      width: calc(100%/3 - 6px);
    }
    @media screen and (max-width: 670px) {
      width: calc(100%/2 - 6px);
    }
  }

}
