.btn {
  background: #2E333C;
  min-height: 30px;
  padding: 6px 12px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  color: #ffffff;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.13em;

  border: 1px solid #00000066;

  &:hover {
    background: #000000;
  }
}
