.form {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &.centered {
    justify-content: center;
    text-align: center;
  }

  .form-input {
    width: 100%;
  }
}
