* {

}
.size_big {
  font-size: 18px;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
}

b {
  letter-spacing: 0.7px;
  font-weight: 600;
}

h2 {
  display: inline;
  text-align: inherit;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.13em;

  @media screen and (max-width: 1200px) {
    font-size: 18px;
  }
}

h3 {
  font-weight: normal;
}

p {
  padding: 6px 0;
  line-height: 1.5em;
}
