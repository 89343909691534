* {
box-sizing: border-box;
}

a,b,h1,h2,h3,h4,h5,p,ul,ol,li,button,form {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background: #2E333C;
  font-family: "Roboto",sans-serif;
  font-size: 13px;
  color: #e2b29a;
}

a {
  color: #ffffff;
}

.home {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);

  & > * {
    width: calc(100% - 12px);
  }
}

.quiz {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
}

.container {
  padding: 0 6px;
  width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1230px) {
    width: 100%;
  }

  &.size_small {
    width: 800px;
    @media screen and (max-width: 830px) {
      width: 100%;
    }
  }

  &.full {
    width: 100%;
  }

  &.v-centered {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }



}
